@import "../../variable/colors.scss";

.transactionList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  border-radius: 12px;
  border: 1px solid $gray-200;
  background: $primary-white;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  //   margin: 10px;

  .transactionListHead {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;

    .transactionListHeadIn {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .transactionListHeadD {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        h3 {
          color: $dark;

          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 162.5%;
          letter-spacing: -0.8px;
        }

        p {
          color: $secondary;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .transactionListHeadBtn {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .filterBtn {
          height: 35px;
          align-items: center;
          border-radius: 6px;
          border: 1px solid $gray-500;
          background: $primary-white;
          // margin: 0px 16px;

          color: $dark;
          /* Xs bold */
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: none;
        }

        .addTransactionBtn {
          display: flex;
          gap: 7px;
          // margin: 0px 16px;
          height: 35px;
          background: $dark;
          border: radius 6px;
          align-items: center;

          color: $primary-white;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: none;
        }
      }
    }

    .transactionListFilter {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      select {
        display: flex;
        width: 210px;
        height: 40px;
        padding: 0px 16px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #ced4da;
        background: $primary-white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

        color: var(--text-666-e-80, #666e80);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        option {
          color: var(--text-666-e-80, #666e80);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .transactionListSearch {
        // height: 40px;
        // width: 42vw;
        // display: flex;
        // gap: 8px;
        // justify-content: space-between;
        height: 40px;
        width: 290px;

        select {
          display: flex;
          width: 155px;
          height: 40px;
          padding: 0px 16px;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #ced4da;
          background: $primary-white;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

          color: var(--text-666-e-80, #666e80);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          option {
            color: var(--text-666-e-80, #666e80);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        // .searchBar {
        //   padding: 8px;
        // }
      }
    }
  }

  .transactionListTable {
    display: flex;
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      border: 1px solid $gray-300;

      border-collapse: collapse;

      thead {
        // display: flex;
        width: 689px;
        height: 40px;
        border: 1px solid $gray-300;
        background: $gray-100;
        background: $gray-100;

        tr th {
          padding: 8px 0px;
          color: rgba(100, 116, 139, 0.7);
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-align: left;
          padding: 0px 20px;
        }
      }
    }

    tbody {
      tr {
        width: 689px;
        padding: 0px 16px;
        border: 1px solid $gray-400;

        td {
          width: 689px;
          padding: 9px 20px;

          text-align: left;
          color: $secondary;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          .operation {
            cursor: pointer;
          }

          .tableDate {
            display: flex;
            // width: 177px;
            flex: 1 0 0;
            gap: 4px;
            text-align: left;

            color: #17181a;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
          }

          .tableMerchent {
            color: $dark;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 162.5%; /* 19.5px */
            text-align: left;
          }
          .tableWallet {
            display: flex;
            align-items: center;
            gap: 12px;
            flex: 1 0 0;
            .tableWalletImg {
              img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
              }
            }
            .tableWalletDetail {
              display: flex;
              padding: 0px 12px;
              flex-direction: column;
              align-items: flex-start;
              flex: 1 0 0;
              .tableMemberName {
                align-self: stretch;
                color: $dark;

                /* h6 */
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 162.5%; /* 26px */
                letter-spacing: -0.8px;
              }
              .tableWalletEmail {
                align-self: stretch;
                color: $secondary;

                /* Small */
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }
          }

          .tableCategory {
            display: flex;
          }

          .tableAmount {
            display: flex;
            text-align: center;
          }

          .tableBalance {
            display: flex;
          }
          .edit {
            display: flex;
            // width: 40px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: center;
    // margin-top: 10px;
  }
  //   }
}

.modaluiOver {
  width: 67vw !important;
  max-width: 58rem !important;
}

.filterModalOver {
  overflow-y: auto;
}

@media screen and (max-width: 800px) {
  .transactionList {
    .transactionListHead {
      .transactionListHeadIn {
        .transactionListHeadD {
          h3 {
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }
        }
        .transactionListHeadBtn {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          .addTransactionBtn {
            font-size: 12px;
          }
        }
      }

      .transactionListFilter {
        .transactionListSearch {
          height: 30px;
          width: auto;
        }
      }
    }

    .transactionListTable {
      tbody {
        tr {
          td {
            font-size: 13px;

            .tableWallet {
              .tableWalletDetail {
                .tableMemberName {
                  font-size: 13px;
                }
                .tableWalletEmail {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .filterModalOver {
    width: 90% !important;
  }
}

@media screen and (max-width: 550px) {
  .filterModalOver {
    height: 90%;
  }
}
