@import "src/variable/colors.scss";

.welcomeCardBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 133px;
  background: rgb(229, 249, 224);
  background: linear-gradient(90deg, rgba(229, 249, 224, 1) 0%, rgba(218, 232, 236, 1) 35%, rgba(174, 63, 55, 1) 95%);

  // border-bottom-right-radius: 16px;
  // border-top-right-radius: 16px;
}

.welcomeCardBoxIn {
  display: flex;
  //   width: 1168px;
  justify-content: space-between;
  align-items: center;
}

.welcomeMessage {
  //   width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 30px 15px 17px 25px;
  justify-content: space-between;
}

.welcomeMessageIn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //   margin: 0px 27px;
}

.welcomeMessage h1 {
  color: $dark;
  /* h3 */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 137%;
  letter-spacing: -0.8px;
}

.welcomeMessage p {
  color: $secondary;
  /* Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.welcomeBtn {
  display: flex;

  display: flex;
  margin: 10px;
  margin-right: 0px;
  gap: 30px;
}

.notification button {
  display: flex;
  height: 40px;
  padding: 0px 24px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--gray-500, #adb5bd);
  background-color: var(--white, #fff);
  color: var(--dark, #1e293b);
  /* Xs bold */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.addFund button {
  display: flex;
  height: 40px;
  padding: 0px 24px;
  align-items: center;
  gap: 7px;
  border-radius: 6px;
  background: #083277;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
}
hr {
  margin: 0px 15px 0px 25px;
  background: #dde0e5;
}
.modaluiOver {
  padding: 45px;
}

@media screen and (max-width: 800px) {
  .welcomeMessage {
    flex-direction: column;
    display: flex;
    margin: 10px 10px 10px 26px;
    align-items: flex-start;
  }

  .welcomeMessage h1 {
    font-size: 20px;
  }

  .welcomeBtn {
    gap: 0px;
    margin-left: 0px;
    width: 100%;
    justify-content: flex-end;
  }

  hr {
    margin-top: -14px;
  }
}
