@import "src/variable/colors.scss";

.modalShadow {
  background-color: rgba(255, 255, 255, 0.244);
  -webkit-backdrop-filter: blur(3.5px);
  backdrop-filter: blur(3.5px);
  .modalui {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // max-width: 800px;
    // max-width: 40vw;
    width: auto;
    z-index: 99;
    // width: auto;
    padding: 20px;
    background-color: $primary-white;
    border-radius: 12px;
    border: none;
    box-shadow:
      0px 2px 4px 0px rgba(0, 0, 0, 0.1),
      0px 1px 10px 0px rgba(0, 0, 0, 0.05);

    // background: $gray-100;
  }
}
