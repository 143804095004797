@import "src/variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 1px 1px 1px 1px;

    .close {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      h5 {
        color: $primary-black;

        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
      button {
        border: none;
        background-color: #ffffff54;
        cursor: pointer;
        border-radius: 50%;
        margin-bottom: 10px;
        padding: 5px;
        padding-bottom: 2px;
      }
    }

    iframe {
      border-radius: 12px;
      // width: 100%;
      //   border-bottom-right-radius: 12px;
    }
  }
}

@media screen and (max-width: 700px) {
  .referModalBox {
    .referModalTop {
      iframe {
        width: 95vw;
      }
    }
  }
}
