@import "src/variable/colors.scss";

.searchBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .searchIcon {
    display: flex;

    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid $gray-400;
    background: $gray-100;
  }

  .searchBar {
    display: flex;
    padding: 8px 4px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid $gray-400;

    input {
      display: flex;
      flex: 1 0 0;
      align-self: stretch;
      border: none;
      outline: none;
      background: transparent;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: $dark;
    }
  }
}
