@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.userPage {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .userPageWelCard {
    display: flex;
    margin-top: 33px;
    background: url("../../assets/images/pinkBg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .welCardLOver {
      height: 75px !important;
      width: 75px !important;
    }
  }
  .userPageHead {
    display: flex;
    // max-width: 1166px;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin: 24px;

    .walletMemberOver {
      min-height: 16.5rem;
    }

    .editIcon {
      width: 14px;
      height: 14px;
      color: #64748b;
    }
  }
  .userPageBottom {
    display: flex;
    align-items: flex-start;
    // gap: 30px;
    margin: 17px;
    justify-content: space-between;
    margin-top: 0;
    height: auto;

    .recentTransOver {
      max-width: 58vw;
    }

    .transCards {
      display: flex;
      flex-direction: column;
      // margin-right: 72.5px;
      gap: 10px;
      height: 543px;
      .WalletTransCardOver {
        // display: flex;

        .balCardOver {
          display: flex;
          height: 150px;
          width: auto;
          min-width: 200px;
          max-width: 300px;
          gap: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 886px) {
  .userPageBottom {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;

    .recentTransOver {
      max-width: 100vw !important;
    }

    .transCards {
      display: flex;
      flex-direction: row !important;
      padding: 0px !important;
      flex-wrap: wrap;
      height: auto !important;
    }
  }
}
