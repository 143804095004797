@import "src/variable/colors.scss";
.walletPage {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;

  .alertmessage {
    position: fixed;
    transform: translate(-50%, -100%);
    transition: transform 1s ease-in-out;
    transform: translateY(-100%);
    z-index: 99;
    display: flex;
    align-items: center;
    // left: 34%;
    top: 90px;
  }

  .walletPageWelCard {
    display: flex;
    width: 100%;
    background: rgb(156, 25, 121);
    // background: radial-gradient(
    //   circle,
    //   rgba(156, 25, 121, 0.9949229691876751) 0%,
    //   rgba(174, 236, 231, 1) 100%
    // );
    background: rgb(139, 212, 247);
    background: linear-gradient(122deg, rgb(255 255 255) 0%, rgb(106, 72, 155) 100%);
  }

  .userPageHead {
    display: flex;
    // flex-wrap: wrap;
    margin: 24px;
    gap: 15px;
    margin-bottom: 0;

    .transCard {
      // display: flex;
      // flex-wrap: wrap;

      // width: 600px;
      width: auto;
      //   width: 50vw;
      columns: 2;
      column-gap: 7px;

      .WalletTransCardOver {
        display: flex;
        margin-bottom: 10px;

        .balCardOver {
          display: flex;
          text-align: left;
          // width: 245px;
          width: 17vw;
        }
      }
    }
    .settingCard {
      // width: 40%;
      width: 45%;
      display: flex;
      margin: 10px;
      margin-bottom: 20px;
    }
  }

  .userPageBottom {
    display: flex;
    margin: 24px;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0px;
    // gap: 30px;

    .userPageBottomL {
      display: flex;
      flex-direction: column;
      width: 65%;
    }
    .userPageBottomR {
      width: 26vw;
      height: auto;
      margin: 10px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .editIcon {
        width: 14px;
        height: 14px;
        color: #64748b;
        cursor: pointer;
        padding: 8px;
      }
    }
  }
}

@media screen and (max-width: 1414px) {
  .walletPage {
    .userPageHead {
      .userPageBottom {
        .userPageBottomR {
          width: 65vw;
        }
      }
    }
  }
}

@media screen and (max-width: 1214px) {
  .walletPage {
    .userPageBottom {
      justify-content: flex-end;
      .userPageBottomL {
        // width: auto;
        width: 100%;

        .recentTransOver {
          max-width: 100%;
        }

        .budgetOver {
          max-width: 100%;
        }
      }

      .userPageBottomR {
        // max-width: 50rem;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1048px) {
  .walletPage {
    .userPageHead {
      .userPageBottom {
        .userPageBottomL {
          width: auto;
        }
        .userPageBottomR {
          display: flex;
          width: 100%;
          height: auto;
          margin: 10px;
        }
      }
      // flex-wrap: wrap;

      .transCard {
        .WalletTransCardOver {
          .balCardOver {
            width: 15vw;
          }
        }
      }
    }

    .userPageBottom {
      .userPageBottomL {
        width: auto;
      }
      .userPageBottomR {
        display: flex;
        width: 100%;
        height: auto;
        margin: 10px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .walletPage {
    .userPageHead {
      flex-wrap: wrap;

      .transCard {
        .WalletTransCardOver {
          .balCardOver {
            width: 35vw;
          }
        }
      }

      .settingCard {
        width: 100%;
      }
    }
  }
}
