@import "src/variable/colors.scss";

@mixin label {
  color: $dark;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.8px; /* 160% */
}

@mixin input {
  display: flex;
  padding: 11px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid $gray-400;
  background: $primary-white;
}

.container {
  // background-color:$primary-white;
  // border-radius: 10px;
  // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;

  background-color: $primary-white;
  /* border-radius: 10px; */
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  position: relative;
  overflow: hidden;
  width: 100vw;

  height: 100vh;

  .formContainer {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;

    // display: inline-flex;
    padding-right: 0px;
    align-items: center;
    gap: 217px;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // width: 356px;

      background-color: $primary-white;

      height: 100%;
      text-align: center;
    }
  }
  .signInContainer {
    left: 0;
    width: 50%;
    z-index: 2;
    transition: all 0.6s ease-in-out;

    form {
      background-color: $primary-white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 20% 0 10%;
      height: 100%;
      text-align: center;
      align-items: center;

      .formIn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        margin-top: -60px;

        .logo {
          display: flex;
          padding: 16px 14px 0px 16px;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          background: $primary-white;
          margin-bottom: -7px;
          align-items: center;

          // img {
          //   width: 322px;
          //   height: 66px;
          // }

          .logoL {
            width: 65px;
          }
          .logoR {
            display: flex;
            display: flex;
            flex-direction: column;
            // gap: 10px;
            .title {
              display: flex;
              color: #1e293b;
              font-size: 30px;
              font-style: normal;
              font-weight: 550;
              line-height: 125%;
              letter-spacing: -0.8px;
              font-family: sans-serif;
            }

            .subTitle {
              display: flex;
              /* width: 35vw; */
              /* font-size: 16px; */
              color: #91969f;
              line-height: 125%;
              letter-spacing: -0.8px;
              text-align: left;
            }
          }
        }

        .header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          h1 {
            color: $dark;

            /* H1 */
            // font-family: Noto Sans;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 50px */
            letter-spacing: -0.8px;
          }
        }

        .formInput {
          display: flex;
          flex-direction: column;
          // align-items: flex-end;
          gap: 16px;
          align-self: stretch;

          .email {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
            }

            input {
              @include input;
            }
          }

          .password {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
              border: none;
            }

            input {
              @include input;
              border: none;
            }

            .error {
              display: flex;
              color: rgb(254, 44, 44);
              gap: 4px;
              font-size: 15px;
              font-style: normal;
              // font-weight: 600;
              line-height: 20.8px;
            }
          }

          .resetPass {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
            }

            input {
              @include input;
              border: none;
            }

            .error {
              display: flex;
              color: rgb(254, 44, 44);
              gap: 4px;
              font-size: 15px;
              font-style: normal;
              // font-weight: 600;
              line-height: 20.8px;
            }
          }

          .checkboxContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .checkboxL {
              display: flex;
              align-items: center;
              gap: 4px;
              input {
                width: 24px;
                height: 20px;
              }

              label {
                color: $dark;
                font-feature-settings:
                  "clig" off,
                  "liga" off;

                /* Xs */
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .checkboxR {
              a {
                color: black;
              }
            }
          }
        }
        .signIn {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          .SignInBtn {
            display: flex;
            width: 356px;
            height: 40px;
            padding: 0px 24px;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border-radius: 6px;
            background: $dark;
          }
        }

        .overlayContainerR {
          display: flex;
          margin-top: -20px;

          .overlayR {
            .overlayRight {
              span {
                color: $dark;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                b {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  .signUpContainer {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;

    form {
      background-color: $primary-white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 20% 0 10%;
      height: 100%;
      text-align: center;
      align-items: center;

      .formIn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        margin-top: -60px;

        // .logo {
        //   display: flex;
        //   padding: 16px 14px 0px 16px;
        //   justify-content: center;
        //   align-items: flex-start;
        //   gap: 8px;
        //   align-self: stretch;
        //   border-radius: 8px;
        //   background: $primary-white;
        //   margin-bottom: -30px;
        //   img {
        //     width: 322px;
        //     height: 66px;
        //   }
        // }

        .logo {
          display: flex;
          padding: 16px 14px 0px 16px;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          background: $primary-white;
          margin-bottom: -7px;
          align-items: center;

          // img {
          //   width: 322px;
          //   height: 66px;
          // }

          .logoL {
            width: 65px;
          }
          .logoR {
            display: flex;
            display: flex;
            flex-direction: column;
            // gap: 10px;
            .title {
              display: flex;
              color: #1e293b;
              font-size: 30px;
              font-style: normal;
              font-weight: 550;
              line-height: 125%;
              letter-spacing: -0.8px;
              font-family: sans-serif;
            }

            .subTitle {
              display: flex;
              /* width: 35vw; */
              /* font-size: 16px; */
              color: #91969f;
              line-height: 125%;
              letter-spacing: -0.8px;
              text-align: left;
            }
          }
        }

        .header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          h1 {
            color: $dark;

            /* H1 */
            // font-family: Noto Sans;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 50px */
            letter-spacing: -0.8px;
          }

          p {
            color: $secondary;

            /* Body */
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
        }

        .formInput {
          display: flex;
          flex-direction: column;
          // align-items: flex-end;
          gap: 16px;
          align-self: stretch;
          .firstName {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
            }

            input {
              @include input;
            }
          }

          .contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
            }

            input {
              @include input;
            }
          }

          .email {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
            }

            input {
              @include input;
            }
          }

          .password {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            label {
              @include label;
            }

            input {
              @include input;
            }
          }

          .checkboxContainer {
            display: flex;
            align-items: center;
            gap: 4px;
            align-self: stretch;
            input {
              width: 24px;
              height: 20px;
            }

            span {
              color: $dark;
              font-feature-settings:
                "clig" off,
                "liga" off;

              /* Xs */
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .signUp {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          width: 100%;
          .SignUpBtn {
            display: flex;
            width: 100%;
            height: 40px;
            padding: 0px 24px;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border-radius: 6px;
            background: $dark;
          }

          .providerBtn {
            display: flex;
            width: 100%;
            height: 40px;
            padding: 0px 24px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 6px;
            border: 1px solid #adbcbd;
            background: #00f0ff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            svg {
            }

            span {
              color: $dark;

              /* Xs bold */
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px; /* 133.333% */
            }
          }
        }

        .overlayContainerL {
          .overlayL {
            .overlayLeft {
              span {
                color: $dark;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                b {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  .rightPanelActive {
    .signInContainer {
      transform: translateX(100%);
    }

    .signUpContainer {
      transform: translateX(100%);
      opacity: 1;
      z-index: 5;
      animation: show 0.6s;
    }
  }

  .overlayContainer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transform: translateX(0);

    transition: transform 0.6s ease-in-out;
    z-index: 100;

    .overlay {
      background: #b187ff;
      background: webkit-linear-gradient(to right, #b187ff, #b187ff);
      background: linear-gradient(to right, #b187ff, #b187ff);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
      color: $primary-white;
      position: relative;
      left: -100%;
      height: 100%;
      width: 200%;
      transform: translateX(0);
      transition: transform 0.6s ease-in-out;

      .overlayRight {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transform: translateX(0);

        transition: transform 0.6s ease-in-out;
        z-index: 100;
        background-image: url(../../assets/images/bgRight.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-start;
        .rightBg {
          display: flex;
          margin-top: 69px;
          .rightCard {
            width: 34.375vw;
            height: 252px;
            flex-shrink: 0;
            border-radius: 6px;
            border: 1px solid $primary-white;
            background: rgba(255, 255, 255, 0.65);
            box-shadow:
              0px 4px 6px -2px rgba(30, 41, 59, 0.1),
              0px 12px 16px -4px rgba(30, 41, 59, 0.04);
            backdrop-filter: blur(4.077422618865967px);
            -webkit-backdrop-filter: blur(4.0774226189px);

            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            color: $dark;
            text-align: center;
            // font-family: Noto Sans;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px; /* 122.222% */
            letter-spacing: -1px;

            .topText {
            }
            .bottomText {
            }
          }
        }
      }
      .overlayLeft {
        background-image: url(../../assets/images/bgLeft.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        /* left: 50%; */
        width: 50%;
        height: 100%;
        overflow: hidden;
        transform: translateX(0);

        transition: transform 0.6s ease-in-out;
        z-index: 100;
        .leftBg {
          .leftCard {
            width: 24.375vw;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            text-align: left;

            .headText {
              color: $primary-white;
              font-size: 50px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -1.389px;
              display: flex;
              flex-direction: column;
            }

            .midText {
              width: 19.1667vw;

              color: $primary-white;

              /* Large */
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 25.2px */
            }

            .bottomText {
              display: flex;
              align-items: center;
              gap: 8px;
              .icon {
                .AvatarGroup {
                  flex-direction: row;
                  .avatar {
                    width: 30px;
                    height: 30px;
                    margin-left: -15px;
                  }
                }
              }
              span {
              }
            }
          }
        }
      }
    }
  }
}

.container.rightPanelActive .signInContainer {
  transform: translateX(60%);

  opacity: 0;
}

.container.rightPanelActive .signUpContainer {
  transform: translateX(100%);
  opacity: 1;
  // z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1px;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.container.rightPanelActive .overlayContainer {
  transform: translateX(-100%);
}

.container.rightPanelActive .overlay {
  transform: translateX(50%);
}

.overlayPanel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlayLeft {
  transform: translateX(-20%);
}

.container.rightPanelActive .overlayLeft {
  transform: translateX(0);
}

.overlayRight {
  right: 0;
  transform: translateX(0);
}

.container.rightPanelActive .overlayRight {
  transform: translateX(20%);
}

.errorMsg {
  display: flex;
  font-size: 10px;
  color: red;
  gap: 2px;

  .errorIcon {
    width: 13px;
    height: 16px;
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    .signInContainer {
      width: 100%;
      // background-image: url(../../assets/images/bgRight.png);
      // background-size: cover;
      display: flex;
      justify-content: center;

      form {
        padding: 10px 40px;

        //     width: 49vw;
        // background-size: cover;
        // padding: 10px 40px;
        // height: auto;
        // padding: 71px 30px 30px 30px;
        // border-radius: 20px;
        // background-color: #ffffff59;
        // backdrop-filter: blur(11px);
      }
    }
    .overlayContainer {
      display: none;
    }
  }
}
