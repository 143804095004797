@import "src/variable/colors.scss";

.welCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 16px;

  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  justify-content: space-between;

  .welCardL {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    .userImage {
      .av {
        display: flex;
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 2px solid white;
      }
    }

    .userName {
      //   text-align: left;
      h3 {
        color: #1e293b;

        /* h3 */
        // font-family: Noto Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 137%; /* 41.1px */
        letter-spacing: -0.8px;
        text-align: left;
      }
      p {
        color: #64748b;

        /* Body */
        // font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }

  .welCardR {
    display: flex;
    align-items: flex-end;
    gap: 8px;

    .cancel {
      display: flex;
      height: 40px;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $gray-500;
      background: $primary-white;

      color: $dark;

      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
    }

    .save {
      display: flex;
      height: 40px;
      padding: 0px 24px;
      align-items: center;
      border-radius: 6px;
      background: $dark;

      color: $primary-white;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}
