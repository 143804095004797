@import "src/variable/colors.scss";

.settingCard {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;

  border-radius: 12px;
  border: 1px solid $gray-300;
  background: $primary-white;

  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

  // width: 368px;
  // height: 345px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    align-self: stretch;
    h3 {
      color: #1e293b;

      /* Large bold */
      //   font-family: Noto Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
    }
    p {
      color: #64748b;

      /* Small */
      // font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  .head {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h6 {
      align-self: stretch;
      color: $dark;

      /* h6 */
      //   font-family: Noto Sans;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 162.5%; /* 26px */
      letter-spacing: -0.8px;
    }

    .formGroup {
      display: flex;
      // gap: 16px;

      .formControlLabel {
        color: #7a7b7b;
        text-align: left;
      }
    }
  }

  .hiddenoption {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .formGroup {
      display: flex;
      color: #7a7b7b;
      text-align: left;
      .formControllabel {
        color: #7a7b7b;
      }
    }
    .hidenInput {
      display: flex;
      input {
        display: flex;
        padding: 8px 4px;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid $gray-400;

        &::placeholder {
          color: $secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        display: none;
      }

      Button {
        display: flex;
        // width: 69px;
        height: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 6px 0px 0px 6px;
        border: 1px solid $gray-400;
        background: #1e293b;
        margin-left: -10px;
        text-transform: none;
      }
    }
  }
}
