@import "../../variable/colors.scss";

.welCard {
  background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 16px 16px;
  border-radius: 12px;
  background: url("../../assets/images/welbg.png");
  // border: 1px solid $gray-200;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;

  .welText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .welCardTitle {
      // align-self: stretch;
      color: #fff;
      letter-spacing: 1px;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      img {
        width: 36px;
        height: 31px;
        position: relative;
        top: 4px;
        right: -9px;
        // mix-blend-mode: darken;
      }
    }

    .welCardText {
      align-self: stretch;
      color: $primary-white;

      /* Body */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      text-align: left;
    }
  }

  .welBtn {
    Button {
      display: flex;
      height: 40px;
      align-items: center;
      gap: 7px;
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.39);
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(1.3600000143051147px);
      -webkit-backdrop-filter: blur(1.3600000143051147px);
      box-shadow: none;
      text-transform: none;
    }
  }
}

.modaluiOver {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media screen and (max-width: 800px) {
  .welCard {
    .welText {
      .welCardTitle {
        font-size: 20px;

        img {
          width: 36px;
          height: 31px;
          position: relative;
          top: 4px;
          right: -9px;
          // mix-blend-mode: darken;
        }
      }

      .welCardText {
        align-self: stretch;
        color: $primary-white;

        /* Body */
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-align: left;
      }
    }

    .welBtn {
      Button {
        height: 30px;
        font-size: 10px;
      }
    }
  }
}
