@import "../../variable/colors.scss";

.react-daterange-picker {
  &__wrapper {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding-left: 12px;
  }

  &__inputGroup {
    margin: 0px 1px;

    &__input::placeholder {
      color: #808080;
    }

    &__input:invalid {
      background: #ffffff00;
    }
  }
}

.react-calendar {
  border-radius: 4px;
  border: 1px solid #ced4da !important;
}

.react-calendar__tile--active {
  background: $dark;
  color: white;

  &:enabled {
    &:hover {
      background: $dark;
      color: white;
    }

    &:focus {
      background: $dark;
      color: white;
    }
  }
}

.react-calendar__tile--hasActive {
  background: $dark;
  color: white;

  &:enabled {
    &:hover {
      background: $dark;
    }

    &:focus {
      background: $dark;
    }
  }
}

// .react-calendar__tile--now {
//   background: $dark;
// }

.placeHolder {
  color: #808080;
}

.noPlaceHolder {
  color: #000000;
}
