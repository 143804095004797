@import "src/variable/colors.scss";

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .filterByDate {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .head {
          color: $dark;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .date {
          display: flex;
          flex-direction: row;
          gap: 50px;
          .startDate {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 250px;

            @include labelInput;

            input {
              color: $dark;
            }
          }
          .endDate {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 250px;

            @include labelInput;

            input {
              color: $dark;

              &::placeholder {
                color: rgb(114, 114, 114);
              }
            }
          }
        }
      }

      .addUser {
        display: flex;
        flex: row;
        gap: 50px;
        .byWallet {
          display: flex;
          flex-direction: column;
          gap: 18px;
          width: 250px;
          // @include labelInput;
          display: flex;
          flex-direction: column;
          gap: 8px;
          label {
            color: $secondary-gray-4;
            /* Secondary / Semibold */

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          input {
            padding: 12px 16px;
            border-radius: 8px;
            background: $primary-white;
            color: $dark;
            /* Body / Regular */

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            // &:hover {
            //   border: 1px solid $dark;
            // }

            // &:focus {
            //   border: 1px solid blue;
            // }
          }

          .autoComplete {
            padding: 0px;
            border-radius: 8px;
            background: $primary-white;
            color: rgba(111, 114, 122, 0.8);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .byUser {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 250px;

          // @include labelInput;

          display: flex;
          flex-direction: column;
          gap: 8px;

          .byUserHead {
            display: flex;
            justify-content: space-between;

            label {
              color: $secondary-gray-4;
              /* Secondary / Semibold */

              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }
          input {
            padding: 12px 16px;
            border-radius: 8px;
            background: $primary-white;
            color: $dark;
            /* Body / Regular */

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            // &:hover {
            //   border: 1px solid $dark;
            // }

            // &:focus {
            //   border: 1px solid blue;
            // }
          }
          .autoComplete {
            padding: 0px;
            border-radius: 8px;
            background: $primary-white;
            color: rgba(111, 114, 122, 0.8);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .categoryFilter {
        display: flex;
        flex-direction: row;
        gap: 50px;

        .byCategory {
          display: flex;
          flex-direction: column;
          gap: 18px;
          width: 250px;
          height: 100px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          // color: $secondary-gray-4;

          .head {
            color: $secondary-gray-4;

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $primary-blue;
        text-align: center;
        /* Body / Bold */

        // font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $primary-blue;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
