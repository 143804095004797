@import "src/variable/colors.scss";

@mixin title {
  width: 171px;
  height: 26px;
  flex-shrink: 0;

  color: $dark;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}
@mixin input {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  // letter-spacing: 1.4px;
}

.referModalBox {
  .referModalTop {
    display: flex;
    // width: 960px;
    padding: 16px 40px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    border-radius: 12px 12px 0px 0px;
    background: var(--secondary-gray-1, #f7f7f9);
    justify-content: space-between;

    .topL {
      display: flex;
      // padding: 30px 55px;
      padding-top: 0px;
      P {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 21px */
      }
    }

    .topR {
      display: flex;
      gap: 8px;
      .closeBtn {
        text-transform: none;
        background: $dark;
      }

      .requestBtn {
        text-transform: none;
        color: $dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }

  .progress {
    display: flex;
    justify-content: space-around;
    height: 400px;
    align-items: center;
  }

  .referModalBottom {
    display: flex;
    margin: 0px 55px;
    overflow-y: auto;
    max-height: 75vh;

    form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;

      .bottomI {
        display: flex;
        align-items: flex-start;
        gap: 70px;
        .DateTime {
          display: flex;
          flex-direction: column;
          width: 170px;
          .title {
            @include title;
          }
          .input {
            @include input;
          }
        }

        .Amount {
          display: flex;
          flex-direction: column;
          width: 170px;

          .title {
            @include title;
          }
          .input {
            @include input;
          }
        }

        .Merchant {
          display: flex;
          flex-direction: column;
          margin-left: 150px;
          .title {
            @include title;
          }
          .input {
            @include input;
          }
        }
      }
      .bottomII {
        display: flex;
        align-items: flex-start;
        gap: 70px;
        .Wallet {
          display: flex;
          flex-direction: column;
          width: 170px;

          .title {
            @include title;
          }

          .input {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            .inputL {
              img {
                border-radius: 50%;
                width: 35px;
                height: 35px;
              }
            }

            .inputR {
              display: flex;
              flex-direction: column;
              .name {
                @include input;
              }

              .walletName {
                @include input;
              }
            }
          }
        }

        .Category {
          display: flex;
          flex-direction: column;
          margin-left: 150px;

          .title {
            @include title;
          }
          .input {
            margin-left: 0px;
            width: 250px;
          }
        }
      }
      .bottomIII {
        display: flex;
        align-items: flex-start;
        gap: 70px;
        .purchasedBy {
          display: flex;
          flex-direction: column;
          width: 170px;

          .title {
            @include title;
          }
          .input {
            display: flex;
            flex-direction: row;
            gap: 8px;
            .inputL {
              img {
                border-radius: 50%;
                width: 35px;
                height: 35px;
              }
            }

            .inputR {
              display: flex;
              flex-direction: column;
              .name {
                @include input;
              }

              .walletName {
                @include input;
              }
            }
          }
        }

        .cardUsed {
          display: flex;
          flex-direction: column;
          margin-left: 150px;

          .title {
            @include title;
          }
          .input {
            @include input;
          }
        }
      }

      .dropNdrag {
        display: flex;
        justify-content: flex-start;
        gap: 152px;

        .bottomIV {
          display: flex;
          flex-direction: column;
          align-items: center;

          .receipt {
            .title {
              @include title;
            }
            .filepondWrapper {
              width: 240px;
            }
          }
        }
        .bottomV {
          display: flex;
          flex-direction: column;
          align-items: center;

          .photo {
            .title {
              @include title;
            }
            .filepondWrapper {
              width: 240px;
            }
          }
        }
      }
      .bottomVI {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: -10px;

        .title {
          @include title;
        }
        textarea {
          display: flex;
          resize: none;
          width: 93%;
          height: 96px;
          padding: 16px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid var(--text-98-a-1-b-3, #98a1b3);
          background: var(--body-ffffff, #fff);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: var(--text-98-a-1-b-3, #98a1b3);
            // text-align: right;
            // font-family: Nunito;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .saveBtn {
          display: flex;
          justify-content: flex-end;
          margin-right: 3%;

          button {
            background-color: $dark;
            text-transform: none;
          }
        }
      }
    }

    #scrollBarH::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    #scrollBarH::-webkit-scrollbar {
      height: 2px;
      background-color: #f5f5f5;
    }

    #scrollBarH::-webkit-scrollbar-thumb {
      background-color: #919191;
    }
  }

  @include scrollbarDesign2;

  @include scrollbarDesignH;

  .footer {
    .saveBtn {
      display: flex;
      justify-content: flex-end;
      margin-right: 5%;
      margin-top: 15px;
      margin-bottom: 15px;

      button {
        background-color: $dark;
        text-transform: none;
      }
    }
  }
}

.filepond--list {
  display: flex;
  flex-wrap: wrap; /* Allow files to wrap to the next row */
  gap: 10px; /* Spacing between files */
  margin-top: 10px; /* Add some spacing between file list and other elements */
}

.filepond--item {
  max-width: 150px; /* Adjust the maximum width of each file */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

/* On hover, scale up the file item slightly */
.filepond--item:hover {
  transform: scale(1.05);
}
