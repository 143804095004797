@import "../../variable/colors.scss";

.reportsWrapper {
  padding: 25px;

  .WelCard {
    display: flex;

    margin-bottom: 25px;
    height: 140px;
  }

  .reportList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: auto;
    padding: 16px 0px;
    border-radius: 12px;
    border: 1px solid $gray-200;
    background: $primary-white;
    /* Shadow cards */
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .reportListHead {
      display: flex;
      padding: 0px 16px;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      align-self: stretch;

      .reportListHeadIn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .reportListHeadD {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;

          h3 {
            color: $dark;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 162.5%;
            letter-spacing: -0.8px;
          }

          p {
            color: $secondary;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .report {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        gap: 30px;

        .reportType {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          label {
            color: black;
            font-size: 15px;
            font-weight: 500;
            width: 100px;
          }
        }

        .reportFilter {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 10px;

          .filterByDate {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
            gap: 30px;

            .startDate,
            .endDate {
              display: flex;
              align-items: center;
              gap: 20px;
              width: 47%;
              flex: 1 1;

              label {
                color: black;
                font-size: 15px;
                font-weight: 500;
                width: 100px;
              }

              input {
                width: 230px;
                height: 40px;
                padding: 0px 10px;
                /* Shadow cards */
                box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
              }

              .inputs {
                display: flex;
                flex-direction: column;
              }
            }
          }

          .byWallet {
            display: flex;
            align-items: center;
            gap: 20px;

            label {
              color: black;
              font-size: 15px;
              font-weight: 500;
              width: 100px;
            }

            input {
              width: 180px;
              height: 23px;
              padding: 0px 10px;
            }

            .inputs {
              display: flex;
              flex-direction: column;
            }
          }

          .reportBtn {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 30px;
            justify-content: center;

            .clearReport {
              height: 35px;
              align-items: center;
              border-radius: 6px;
              border: 1px solid #adb5bd;
              background: #fff;
              color: #1e293b;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              text-transform: none;
            }
            .downloadReport {
              display: flex;
              gap: 7px;
              height: 35px;
              background: #1e293b;
              align-items: center;
              color: #fff;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: none;
            }
          }
        }
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

@media screen and (max-width: 500px) {
  .reportType,
  .filterByDate,
  .startDate,
  .endDate,
  .byWallet {
    flex-wrap: wrap;
  }
}
