@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.WalletTransCard {
  .balCard {
    display: flex;
    width: 232.5px;
    height: 165px;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid $gray-200;
    background: $primary-white;
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
    margin: 10px;
  }

  .cardIcon {
    // width: 32px;
    // height: 32px;
    border-radius: 6px;
    // background-color: #1e293b;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: -16px;
    }
  }

  .cardDetail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .cardDetailTitle {
      color: $secondary;
      font-weight: 400;
      line-height: 150%;
    }

    .cardDetailValue {
      color: #1e293b;
      font-size: 24px;
      font-weight: 600;
      height: 33px;
      letter-spacing: -0.8px;
      line-height: 32.9px;
    }

    .perGrowth {
      display: flex;
      align-items: center;
      gap: 4px;

      .growth {
        display: flex;
        padding: 4px 8px;
        padding-left: 0;
        align-items: center;
        gap: 2px;
      }

      .upward {
        color: green;
      }

      .downward {
        color: red;
      }

      .fromDay {
        color: $secondary;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .WalletTransCard {
    .balCard {
      display: flex;
      width: 155.5px;
      height: 165px;
      padding: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid $gray-200;
      background: $primary-white;
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      margin: 2px;
    }

    .cardIcon {
      // width: 32px;
      // height: 32px;
      border-radius: 6px;
      // background-color: #1e293b;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: -16px;
      }
    }

    .cardDetail {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .cardDetailTitle {
        color: $secondary;
        font-weight: 400;
        line-height: 150%;
      }

      .cardDetailValue {
        color: #1e293b;
        font-size: 20px;
        font-weight: 600;
        height: 33px;
        letter-spacing: -0.8px;
        line-height: 32.9px;
      }

      .perGrowth {
        display: flex;
        align-items: center;
        gap: 4px;

        .growth {
          display: flex;
          padding: 4px 8px;
          padding-left: 0;
          align-items: center;
          gap: 2px;
        }

        .upward {
          color: green;
        }

        .downward {
          color: red;
        }

        .fromDay {
          color: $secondary;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}
