@import "../../../variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .filterByDate {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .head {
          color: $dark;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .date {
          display: flex;
          flex-direction: row;
          gap: 50px;
          .startDate {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            gap: 4px;
            // width: 250px;
            width: 46%;

            @include labelInput;

            input {
              color: $dark;
              border: none;
            }
          }
          .endDate {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            gap: 4px;
            // width: 250px;
            width: 46%;

            @include labelInput;

            input {
              color: $dark;
              border: none;

              &::placeholder {
                color: rgb(114, 114, 114);
              }
            }
          }
        }
      }

      .filterByAmt {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .head {
          color: $dark;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .amt {
          display: flex;
          flex-direction: row;
          gap: 37px;
          .min {
            display: flex;
            flex: 1 1;
            width: 46%;
            flex-direction: column;
            gap: 4px;
            @include labelInput;

            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
              display: none;
            }
          }

          .max {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            width: 46%;
            gap: 4px;
            @include labelInput;

            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }

      .addUser {
        display: flex;
        flex: row;
        gap: 50px;

        .byUser {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 250px;

          // @include labelInput;

          display: flex;
          flex-direction: column;
          gap: 8px;
          .byUserHead {
            display: flex;
            justify-content: space-between;

            .userlabel {
              color: $dark;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            label {
              color: $secondary-gray-4;
              /* Secondary / Semibold */

              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }

            .toggleBodyOver {
              height: 25px;
              width: 175px;
              border-radius: 15px;

              &:before {
                font-size: 13px;
                top: 0px;
                right: 13px;
              }

              &:after {
                height: 10px;
                font-size: 13px;
                /* top: 2px; */
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 5px 6px 7px 6px;
                border-radius: 12px;
              }
            }

            .myToggleSwitch {
              input[type="checkbox"] {
                cursor: pointer;
                width: 50px;
                height: 25px;
                opacity: 0;
                position: absolute;
                top: 0;
                z-index: 1;
                margin: 0px;

                &:checked {
                  + label.toggleBodyOver {
                    &:after {
                      content: attr(data-on);
                      left: 85px;
                      cursor: pointer;
                      padding: 5px 6px 7px 6px;
                    }

                    &:before {
                      content: attr(data-off);
                      right: auto;
                      left: 13px;
                      top: 0px;
                    }
                  }
                }
              }
            }
          }
          input {
            padding: 12px 16px;
            border-radius: 8px;
            background: $primary-white;
            color: $dark;
            /* Body / Regular */

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            // &:hover {
            //   border: 1px solid $dark;
            // }

            // &:focus {
            //   border: 1px solid blue;
            // }
          }
          .autoComplete {
            padding: 0px;
            border-radius: 8px;
            background: $primary-white;
            color: rgba(111, 114, 122, 0.8);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $primary-blue;
        text-align: center;
        /* Body / Bold */

        // font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $primary-blue;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .referModalBox {
    .referModalTop {
      form {
        .filterByDate,
        .filterByAmt {
          .date,
          .amt {
            gap: 8px;
          }
        }
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
