@import "../../variable/colors.scss";

.cardEditWrapper {
  padding: 24px 40px;
  // width: 600px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    h5 {
      color: $primary-black;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .body {
    height: 220px;

    div {
      display: flex;
    }

    .label {
      color: $secondary;
      display: block;
      width: 250px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      gap: 6px;
    }

    .value {
      color: $dark;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      cursor: pointer;
    }
  }
}

.bottom {
  background: $secondary-gray-1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .bottomContainer {
    padding: 8px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancelBtn {
      border-radius: 8px;
      justify-content: center;
      color: $primary-blue;
      text-align: center;
      /* Body / Bold */

      // font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }

    .saveBtn {
      display: flex;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: $primary-blue;

      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }
  }
}

.mt10 {
  margin-top: 10px;
}

.progress {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpInputContainer {
  margin-top: 15px;
  margin-left: 25px;

  input {
    width: 50px;
  }

  fieldset {
    border: 2px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  div {
    justify-content: center;
  }
}

.pinbottom {
  background: $secondary-gray-1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .pinbottomContainer {
    padding: 8px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -25px;

    .pincancelBtn {
      border-radius: 8px;
      justify-content: center;
      color: $dark;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }

    .pinsaveBtn {
      display: flex;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: $dark;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
    }
  }
}

.pinEditWrapper {
  width: 600px;

  .pinheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin: 24px 40px;
    margin-bottom: 0px;
  }

  .pinbody {
    margin: 24px 40px;
    margin-top: 0px;

    div {
      display: flex;
      justify-content: center;
    }

    .label {
      color: $secondary;
      display: block;
      width: 250px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      gap: 6px;
    }

    .value {
      color: $dark;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      cursor: pointer;
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 15px;
  font-style: normal;
  // font-weight: 600;
  line-height: 20.8px;
}

.errIcon {
  width: 15px;
}

.test {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pinTitleContainer {
  display: flex;
  margin-top: 50px;
  margin-right: 351px;
}

.pinTitle {
  color: #726e6e;
  margin-right: 29.1px;
  margin-bottom: 8px;
}

.confirmpinTitleContainer {
  display: flex;
  margin-top: 50px;
  margin-right: 329px;
}

.confirmpinTitle {
  color: #726e6e;
}

.visibilityIcon {
  position: relative;
  left: 23px; /* Adjust this value as needed */
  color: #726e6e;
}

.confirmvisibilityIcon {
  margin-left: 30px;
  color: #726e6e;
}

@media screen and (max-width: 800px) {
  .editModalOver{
    width: 90% !important;
  }
  .pinModalOver {
    width: auto;
  }

  .cardEditWrapper {
    .body {
      .label {
        width: 30vw;
      }
    }
  }
  .pinEditWrapper{
    width: 323px;;
  }

  .otpInputContainer input {
    width: 19px;
  }

  .otpInputContainer {
    margin-left: 12px;
  }

  .visibilityIcon, .confirmvisibilityIcon{
    position: relative;
    bottom: 3px;
  }


  .pinEditWrapper .pinbody {
    margin: 24px 90px;
    margin-top: 0px;
  }

  .pinbottom, .pinbottomContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -15px;
  }

  .pinbottom{
    background-color: white;
  }

  .pinsaveBtn{
    bottom: 15px;
  }

  .pincancelBtn{
    bottom: 14px;
  }


  .pinTitleContainer, .confirmpinTitleContainer {
    width: auto; 
    flex-wrap: nowrap; 
  }

  .pinTitle {
    white-space: nowrap;
    margin-left: 64px;
  }
  .confirmpinTitle {
    white-space: nowrap; 
    margin-left: 86px;
  }
}






