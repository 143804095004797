@import "src/variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .email {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .emailtoggle {
          display: flex;
          justify-content: space-between;

          label {
            color: $secondary-gray-4;
            /* Secondary / Semibold */

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .toggleBodyOver {
          height: 25px;
          width: 175px;
          border-radius: 15px;

          &:before {
            font-size: 13px;
            top: 0px;
            right: 13px;
          }

          &:after {
            height: 10px;
            font-size: 13px;
            /* top: 2px; */
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 5px 6px 7px 6px;
            border-radius: 12px;
          }
        }

        .myToggleSwitch {
          input[type="checkbox"] {
            cursor: pointer;
            width: 50px;
            height: 25px;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 1;
            margin: 0px;

            &:checked {
              + label.toggleBodyOver {
                &:after {
                  content: attr(data-on);
                  left: 85px;
                  cursor: pointer;
                  padding: 5px 6px 7px 6px;
                }

                &:before {
                  content: attr(data-off);
                  right: auto;
                  left: 13px;
                  top: 0px;
                }
              }
            }
          }
        }

        input {
          padding: 12px 16px;
          border-radius: 8px;
          // border: 1px solid $secondary-gray-2;
          background: $primary-white;
          // color: rgba(111, 114, 122, 0.8);
          /* Body / Regular */

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        .supporterOption li {
          display: flex;
          flex-direction: column;

          .supporterName {
            font-size: 12px;
            color: blue;
          }
          .supporterEmail {
            font-size: 12px;
            color: rgb(125, 147, 54);
          }
          .supporterRole {
            font-size: 12px;
            color: rgb(206, 37, 51);
          }
        }
      }

      .formfill {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .RadioInput {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          label {
            color: $secondary-gray-4;
            /* Secondary / Semibold */

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          .inputs {
            display: flex;
            align-items: flex-start;
            gap: 40%;
            .inputYes {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 4px;
              border-radius: 4px;
              background: $primary-white;
            }

            .inputNo {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 4px;
              border-radius: 4px;
              background: $primary-white;
            }
          }
        }

        .name,
        .addressrowI,
        .addressrowII,
        .orgn {
          display: flex;
          justify-content: space-between;
          gap: 80px;
          flex-wrap: wrap;

          .Fname,
          .Lname,
          .mobile,
          .address,
          .city,
          .state,
          .postCode,
          .organisation {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include labelInput;
            box-sizing: border-box;

            //   input {
            //     width: 100%;
            //   }
          }

          .city,
          .state,
          .postCode {
            flex: 1 1 1;
          }

          .organisation {
            .autoComplete {
              input {
                border: none;
              }
            }
          }
        }

        .addressrowII {
          gap: 20px;
          flex-wrap: nowrap;
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $primary-blue;
        text-align: center;
        /* Body / Bold */
        // font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: auto;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #1e293b;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

.progress {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
