@import "src/variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 25vw;
      min-width: 300px;

      .progress {
        display: flex;
        justify-content: center;
      }

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        .editIcon {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .email {
        display: flex;
        flex-direction: column;
        gap: 8px;

        label {
          color: $secondary-gray-4;
          /* Secondary / Semibold */

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        input {
          padding: 12px 16px;
          border-radius: 8px;
          border: 1px solid $secondary-gray-2;
          background: $primary-white;
          // color: rgba(111, 114, 122, 0.8);
          /* Body / Regular */

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .RadioInput {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        label {
          color: $secondary-gray-4;
          /* Secondary / Semibold */

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .inputs {
          display: flex;
          align-items: flex-start;
          gap: 20px;
          .inputYes {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            background: $primary-white;

            label {
              color: var(--text-666-e-80, #666e80);
              text-align: right;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .inputNo {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            background: $primary-white;

            label {
              color: var(--text-666-e-80, #666e80);
              text-align: right;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        /* Body / Bold */

        // font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        border-color: $dark;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: auto;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #1e293b;

        font-weight: 600;
        line-height: 24px;

        text-transform: none;
      }
    }
  }
}
