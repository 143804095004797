@import "../../../variable/colors.scss";

.rootContainer {
  display: flex;
  justify-content: center;
  margin: 25px;
  flex-direction: column;

  .modalTop {
    margin: 0px 40px;
    margin-right: 80px;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      box-sizing: border-box;

      .generalCompany,
      .mainContact {
        display: flex;
        flex-direction: column;
        gap: 16px;
        box-sizing: border-box;
        .close {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          h5 {
            color: $primary-black;

            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
          button {
            border: none;
            background-color: $primary-white;
            cursor: pointer;
            margin-right: -40px;
          }
        }

        .row {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          // flex-wrap: wrap;

          .companyName,
          .abnName,
          .mobile,
          .role,
          .website,
          .address,
          .city,
          .state,
          .postCode,
          .name,
          .role,
          .email,
          .phone,
          .facebook,
          .linkedIn,
          .instagram {
            // flex: 1;
            display: flex;
            // flex-direction: column;
            gap: 8px;
            @include labelInput;
            box-sizing: border-box;
            align-items: center;
            flex: 1 1;
            input {
              width: 12vw;
            }

            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
              display: none;
            }

            textarea {
              resize: none;
              height: 100px;
              padding: 12px 16px;
              border-radius: 8px;
              border: 1px solid $secondary-gray-2;
              background: $primary-white;
              color: $dark;
              /* Body / Regular */

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              width: 12vw;
            }

            textarea,
            input[type="text"] {
              font-family: Arial;
            }

            textarea {
              &::placeholder {
                color: rgb(146, 141, 141);
              }
            }

            label {
              width: 125px;
            }
          }
        }

        .rowII {
          .mobile {
            input {
              height: 26px;
            }
          }
        }
      }
    }
  }

  .modalBottom {
    //   background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 0px;

      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #1e293b;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
        width: 115px;
        font-size: 16px;
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

@media screen and (max-width: 700px) {
  .rootContainer {
    .modalTop {
      form {
        .generalCompany,
        .mainContact {
          .row,
          .rowII {
            flex-direction: column;

            .companyName,
            .abnName,
            .mobile,
            .role,
            .website,
            .address,
            .city,
            .state,
            .postCode,
            .name,
            .role,
            .email,
            .phone,
            .facebook,
            .linkedIn,
            .instagram {
              width: 100%;
              input {
                width: 100% !important;
              }

              textarea {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .rootContainer {
    .modalTop {
      margin: 0px 10px;
      margin-right: 50px;

      form {
        .generalCompany,
        .mainContact {
          .close {
            h5 {
              font-size: 15px;
            }
          }
          .row,
          .rowII {
            .companyName,
            .abnName,
            .mobile,
            .role,
            .website,
            .address,
            .city,
            .state,
            .postCode,
            .name,
            .role,
            .email,
            .phone,
            .facebook,
            .linkedIn,
            .instagram {
              label {
                font-size: 10px;
                font-weight: 600;
              }
              input {
                padding: 6px 16px;
                font-size: 11px;
              }

              textarea {
                padding: 6px 16px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
