@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.Dashboard {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .welcomeCard {
    width: 100%;
    height: 133px;
  }

  .topWallet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: auto;
    margin: 15px;
    margin-right: 0px;

    .topWalletHead {
      display: flex;
      gap: 8px;
      flex-direction: column;
      width: 100%;

      .topWalletHeadIn {
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 10px;

        .topWalletHeadL {
          display: flex;
          gap: 20px;
          align-items: center;

          h1 {
            color: $dark;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 137%;
            letter-spacing: -0.8px;
          }

          .walletSearch {
            display: flex;
            height: 38px;
          }
        }

        .topWalletHeadR {
          display: flex;

          button {
            text-transform: none;
          }
        }
      }

      .walletCards {
        display: flex;
        /* width: 1260px; */
        align-items: flex-end;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
      }
    }

    .transCards {
      display: flex;
      /* width: 1168px; */
      align-items: flex-end;
      /* gap: 30px; */
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .DashTrans {
    display: flex;
    // flex-wrap: wrap;
    margin: 15px;
    @media (max-width: 1328px) {
      flex-wrap: wrap;
    }

    .recentTransOver {
      max-width: 100%;
    }
  }

  // }
}

@media screen and (max-width: 800px) {
  .Dashboard {
    .topWallet {
      .topWalletHead {
        .topWalletHeadIn {
          .topWalletHeadL {
            flex-direction: column;
            align-items: flex-start;
            h1 {
              font-size: 20px;
            }
          }
          .topWalletHeadR {
            margin-right: 10px;
          }
        }
        .walletCards {
          display: flex;
          align-items: flex-end;
          // flex-wrap: wrap;
          flex-wrap: nowrap;
          flex-direction: row;
          width: 100%;
          overflow-x: auto;
        }
      }
      .transCards {
        display: flex;
        /* width: 1168px; */
        align-items: flex-end;
        /* gap: 30px; */
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .DashTrans {
      .recentTransOver {
        max-width: 100%;
      }
    }
  }
}
