$bg-color: #f9fafb;
$dark: #1e293b;

$primary-black: #232325;

$primary-white: #fff;

$light-grey: #ced4da;

$gray-100: #f9fafb;
$gray-200: #e9ecef;
$gray-300: #dde0e5;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$dark-grey: #64748b;
$secondary: #64748b;
$secondary-gray-1: #f7f7f9;
$secondary-gray-2: #e0e2e7;
$secondary-gray-4: #6f727a;

$bright-blue: #1b46c2;
$primary-blue: #2e65f3;

$success: #67c23a;

@mixin outlineBtn {
  border: 1px solid $light-grey;
  color: $dark-grey;
  font-weight: 400;

  &:hover {
    border: 1px solid $light-grey;
    color: $bright-blue;
    font-weight: 550;
  }
}

@mixin labelInput {
  label {
    color: $secondary-gray-4;
    /* Secondary / Semibold */

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  input {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid $secondary-gray-2;
    background: $primary-white;
    color: $dark;
    /* Body / Regular */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &:hover {
      border: 1px solid $dark;
    }

    &::placeholder {
      color: rgba(111, 114, 122, 0.8);
    }
    // &:focus {
    //   border: 1px solid blue;
    // }
  }
}

@mixin gradient {
  @keyframes gradient {
    0% {
      background: radial-gradient(circle at center, rgba(82, 82, 82, 0) 0%, #fff 0%, #fff 100%);
    }
    25% {
      background: radial-gradient(circle at center, rgba(129, 128, 128, 0.3) 24%, #fff 25%, #fff 100%);
    }
    50% {
      background: radial-gradient(circle at center, rgba(172, 170, 170, 0.5) 49%, #fff 50%, #fff 100%);
    }
    75% {
      background: radial-gradient(circle at center, rgba(177, 177, 177, 0.8) 74%, #fff 75%, #fff 100%);
    }
    100% {
      color: #fff;
      background: radial-gradient(circle at center, rgb(179, 179, 179) 99%, #fff 100%, #fff 100%);
    }
  }
}

@mixin scrollbarDesign {
  #scrollBar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  #scrollBar::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  #scrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(33, 72, 149)),
      color-stop(0.72, rgb(32, 77, 132)),
      color-stop(0.86, rgb(25, 45, 104))
    );
  }
}

@mixin scrollbarDesign2 {
  #scrollBar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  #scrollBar::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  #scrollBar::-webkit-scrollbar-thumb {
    background-color: #919191;
  }
}
@mixin scrollbarDesignH {
  #scrollBarH::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  #scrollBarH::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
  }

  #scrollBarH::-webkit-scrollbar-thumb {
    background-color: #919191;
  }
}
