@import "../../variable/colors.scss";
* {
  margin: 0;
  padding: 0;
}

.users {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
  // gap: 8px;

  .userWelCard {
    display: flex;
    margin: 33px 15px 15px 25px;
  }

  .userTableCard {
    display: flex;
    margin: 15px 15px 25px 25px;
  }
}
