@import "../../variable/colors.scss";
* {
  margin: 0;
  padding: 0;
}

.wallet {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .walletWelCard {
    display: flex;
    margin: 33px 15px 15px 25px;

    height: 192px;
  }

  .transCards {
    display: flex;
    flex-direction: row;
    width: auto;
    // gap: 10%;
    margin: 25px;
    margin-top: -77px;
    justify-content: space-between;

    .WalletTransCardOver {
      .balCardOver {
        width: 16vw;
        // height: 20vh;
        border-radius: 6px;
        border: 1px solid $primary-white;
        background: rgba(255, 255, 255, 0.75);

        /* With Blur */
        box-shadow: 0px 12px 16px -4px rgba(12, 26, 36, 0.04);
        -webkit-backdrop-filter: blur(13.600000381469727px);
        backdrop-filter: blur(13.600000381469727px);
        gap: 16px;

        display: flex;
        justify-content: flex-start;

        .cardDetailOver {
          gap: 8px;
          .cardDetailTitleOver {
            text-align: left;
          }
        }
      }
    }
  }

  .walletListtable {
    display: flex;
    margin: 15px 15px 25px 25px;
  }
}

@media screen and (max-width: 800px) {
  .wallet {
    .transCards {
      gap: 8px;
      overflow-x: scroll;
      .WalletTransCardOver {
        .balCardOver {
          width: 9.5rem;
        }
      }
    }
  }
}
