@import "src/variable/colors.scss";

.Budget {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 722px;
  width: auto;
  height: auto;
  padding: 24px 16px;
  border-radius: 12px;
  border: 1px solid $gray-300;
  background: var $primary-white;
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  margin: 10px;

  .BudgetBox {
    display: flex;
    // width: 100%;
    height: auto;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid $gray-200;
    background: $primary-white;
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .head {
      display: flex;
      padding: 0px 16px;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      justify-content: space-between;

      .titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        h6 {
          color: $dark;
          font-size: 16px;
          font-weight: 600;
          line-height: 162.5%;
          letter-spacing: -0.8px;
        }

        p {
          color: $secondary;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .button {
        display: flex;
        align-items: flex-start;
        /* gap: 16px; */
        align-self: stretch;
        justify-content: space-between;
        gap: 4px;

        .btn {
          .sendBtn {
            background-color: rgba(16, 92, 30, 0.769);
            text-transform: none;
          }
        }
        .buttonR {
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          border: 1px solid $gray-300;
          background: $primary-white;
          box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

          color: $secondary;
          height: 20px;

          cursor: pointer;
        }
      }
    }

    .table {
      display: flex;
      width: 100%;
      margin-bottom: -15px;
      .rcTable {
        width: 100%;
      }

      table {
        width: 100%;
        // border: 1px solid $gray-400;
        border-collapse: collapse;

        thead {
          width: 689px;
          height: 40px;
          border: 1px solid $gray-300;
          background: $gray-100;
          padding: 0px 16px;
          align-items: center;
          gap: 8px;
          border-left: none;
          border-right: none;

          tr th {
            padding: 8px 0px;
            color: rgba(100, 116, 139, 0.7);
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
            padding: 0px 8px;
          }
        }

        tbody {
          tr {
            // width: 689px;
            padding: 0px 16px;
            // border: 1px solid black; /* add border */
            // border-top: none; /* remove top border */
            border: none;
            border-top: 1px solid $gray-400;

            td {
              // width: 689px;
              padding: 0px 13px 10px;

              text-align: left;
              color: $secondary;
              /* Small */
              //   font-family: Noto Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;

              .budgetname {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-top: 21px;
                input {
                  display: flex;
                  // wi/dth: 190px;
                  // width: 9vw;
                  height: 30px;
                  padding: 0px 16px;
                  align-items: center;
                  gap: 10px;
                  flex-shrink: 0;
                  border-radius: 8px;
                  border: 1px solid var(--body-e-1-e-2-e-6, #e1e2e6);
                  background: $primary-white;
                }

                .masterItem {
                  display: flex;
                  color: #2e65f3;
                  padding-left: 6px;
                  font-size: 11.5px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  cursor: pointer;
                  width: fit-content;
                  text-decoration: underline;

                  &:hover {
                    color: #2350c6;
                  }
                }
              }

              .amount {
                input {
                  display: flex;
                  width: 50%;
                  height: 30px;
                  padding: 0px 16px;
                  align-items: center;
                  gap: 10px;
                  flex-shrink: 0;
                  border-radius: 8px;
                  border: 1px solid var(--body-e-1-e-2-e-6, #e1e2e6);
                  background: $primary-white;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                  display: none;
                }
              }

              .edit {
                display: flex;
                justify-content: center;
                cursor: pointer;
              }

              .editIcon {
                color: rgba(213, 52, 52, 0.784);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

// Media Queries
@media (max-width: 768px) {
  .Budget {
    max-width: 100%;
  }

  .Budget .BudgetBox {
    // padding: 12px;
  }

  .Budget .BudgetBox .head .titles h6 {
    font-size: 14px;
  }

  .Budget .BudgetBox .head .titles p {
    font-size: 12px;
  }

  .Budget .BudgetBox .table thead tr th {
    font-size: 10px;
    padding: 0px 12px;
  }
}
