@import "src/variable/colors.scss";
.userHead {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid $gray-300;
  background: $primary-white;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .headL {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      h3 {
        color: $dark;

        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
      p {
        color: $secondary;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .headR {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid $gray-300;
      background: $primary-white;
      /* Shadow cards */
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      cursor: pointer;
      .editIcon {
        width: 14px;
        height: 14px;
        color: #64748b;
      }
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    .label {
      color: $secondary;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: flex;
      align-items: flex-start;
      gap: 6px;
      .value {
        color: $dark;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        cursor: pointer;
      }
    }
    .toggleBodyOver {
      height: 25px;
      width: 145px;
      border-radius: 15px;
      &:before {
        font-size: 13px;
        top: 0px;
        right: 17px;
      }
      &:after {
        height: 10px;
        font-size: 13px;
        width: 60px;
        top: 1.2px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px 6px 7px 6px;
        border-radius: 12px;
      }
    }
    .myToggleSwitch {
      input[type="checkbox"] {
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
        &:checked {
          + label.toggleBodyOver {
            &:after {
              content: attr(data-on);
              left: 69px;
              cursor: pointer;
              padding: 5px 6px 7px 6px;
              width: 60px;
              top: 1.2px;
              background-color: #52ad52;
              color: white;
            }
            &:before {
              content: attr(data-off);
              right: auto;
              left: 13px;
              top: 0px;
            }
          }
        }
      }
    }
    .bottomInfo {
      display: flex;
      align-items: flex-start;
      gap: 6px;
    }
  }
}

.modaluiOver {
  max-height: 95vh;
  overflow-y: auto;
}

@media screen and (max-width: 800px) {
  .modaluiOver {
    width: 90% !important;
  }
}
