@import "src/variable/colors.scss";

gradient {
  @include gradient;
}

.walletMember {
  display: flex;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  justify-content: space-between;
  padding: 5px;
  a {
    display: contents;

    .img {
      img {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 50%;
      }
    }
    .info {
      display: flex;
      padding: 0px 4px;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      .name {
        color: $dark;

        /* small bold */
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }
      .walletName {
        color: $secondary;

        /* Small */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
      .role {
        display: flex;
        padding: 4px 4px;
        align-items: center;
        border-radius: 6px;
        border: 1px solid;
        background: none;
        height: 15px;
        color: #70716f;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 450;
        line-height: 125%;
        letter-spacing: 0.5px;
        justify-content: center;
      }
    }
  }
  .edit {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 6px;
    border: 1px solid $gray-300;
    background: $primary-white;

    /* Shadow cards */
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .editIcon {
      width: 21px;
      height: 30px;
      color: #cf2424;
      cursor: pointer;
    }
    .editIconn {
      width: 21px;
      height: 21px;
      color: #757575;
      cursor: pointer;
    }

    &:hover {
      // background: rgba(28, 52, 90, 0.12);
      border-radius: 6px;
      -webkit-backdrop-filter: blur(3.5px);
      backdrop-filter: blur(3.5px);
      box-shadow: 4px 4px 6px 3px rgba(182, 182, 182, 0.217);
      transform: translateY(-0.1ch);
    }

    &:active {
      animation: gradient 50ms;
      background: rgba(52, 70, 100, 0.325);
    }
  }
}

.clickable {
  &:hover {
    background: rgba(28, 52, 90, 0.12);
    border-radius: 8px;
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(3.5px);
    backdrop-filter: blur(3.5px);
    border: 1px solid rgba(28, 52, 90, 0.25);
  }

  &:active {
    animation: gradient 50ms;
    background: rgba(52, 70, 100, 0.325);
  }
}
