.btnContainer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  // label {
  //   font-size: 15px;
  //   color: #424242;
  //   font-weight: 450;
  // }

  .btnColorModeSwitch {
    display: inline-block;
    margin: 0px;
    position: relative;
    font-size: 15px;
    color: #424242;
    font-weight: 450;

    .btnColorModeSwitchInner {
      margin: 0px;
      width: 195px;
      height: 39px;
      background: #e0e0e0;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease;
      display: block;
      cursor: pointer;

      &:before {
        content: attr(data-on);
        position: absolute;
        font-size: 15px;
        font-weight: 450;
        top: 8.5px;
        right: 20px;
      }

      &:after {
        content: attr(data-off);
        width: 75px;
        height: 25px;
        background: #fff;
        border-radius: 4px;
        position: absolute;
        left: 5px;
        top: 2px;
        text-align: center;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 6px -2px #111;
        padding: 6.5px 5px 3.5px 5px;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
      width: 50px;
      height: 25px;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 1;
      margin: 0px;

      &:checked {
        + label.btnColorModeSwitchInner {
          &:after {
            content: attr(data-on);
            left: 105px;
            cursor: pointer;
          }

          &:before {
            content: attr(data-off);
            right: auto;
            left: 20px;
          }
        }

        ~ .alert {
          display: block;
        }
      }
    }
  }
}
