@import "src/variable/colors.scss";

.container {
  background-color: $bg-color;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .navContainer {
    height: 48.4rem;
    width: 230px;
    padding: 5px;
    border: 1px solid #e9ecef;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .nav {
      margin-bottom: 40px;
      height: 40px;
      border-radius: 6px;
    }

    .navInput {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin: 5px 5px 15px 10px;
      align-items: center;

      .icon {
        border-radius: 6px;
        height: 35px;
        width: 35px;
      }

      .iconTitle {
        height: 50px;
        width: 140px;
      }
    }

    .refer {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin: 5px 5px 15px 10px;
      align-items: center;
      margin-top: 100px;

      .icon {
        border-radius: 6px;
        height: 35px;
        width: 35px;
      }

      .iconTitle {
        height: 50px;
        width: 140px;
      }
    }

    .companyIcon {
      border-radius: 6px;
      height: 80px;
      margin-top: 9rem;
    }
  }

  .bodyContainer {
    .setContainer {
      width: 84vw;
      padding: 5px;
      border: 1px solid #e9ecef;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .breadcrum {
        width: 100px;
        height: 37px;
      }
      .setR {
        display: flex;
        gap: 10px;
        margin-right: 10px;

        .search {
          width: 10vw;
        }

        .setIcon {
          width: 20px;
          border-radius: 100%;
        }
      }
    }

    .mainBody {
      display: flex;
      flex-direction: column;
      gap: 75px;
      padding: 36px 100px;

      .bodyIn {
        height: 285px;
        border-radius: 12px;
      }
    }
  }
}
