@import "../../variable/colors.scss";
* {
  margin: 0;
  padding: 0;
}

.transactions {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .transactionWelCard {
    display: flex;
    margin: 15px;
    margin-top: 33px;
    height: 140px;
  }

  .transactionTableCard {
    display: flex;
    margin: 15px;
  }
}
