@import "../../variable/colors.scss";

.welCard {
  // background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 16px 16px;
  border-radius: 12px;
  // border: 1px solid $gray-200;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  justify-content: space-between;
  filter: contrast(120%) brightness(100%);
  background: linear-gradient(279deg, rgba(167, 80, 209, 1), rgba(113, 73, 73, 0)),
    linear-gradient(106deg, rgba(76, 143, 218, 1), rgba(0, 255, 0, 0)),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 255 255' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2.9' numOctaves='6' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  // background: url("../welbg.png");
  // background-size: cover;
  // background-repeat: no-repeat;

  // height: 192px;
  .welL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    .welText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .welCardTitle {
        align-self: stretch;
        color: #fff;
        letter-spacing: 1px;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        // display: flex;

        img {
          width: 60px;
          height: 30px;
          // margin-bottom: -3px;
          // margin-left: -12px;
          position: relative;
          top: 4px;
          right: 15px;
          // mix-blend-mode: darken;
        }
      }

      .welCardText {
        align-self: stretch;
        color: $primary-white;

        /* Body */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-align: left;
      }
    }

    .welBtn {
      Button {
        display: flex;
        height: 40px;
        align-items: center;
        gap: 7px;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.39);
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(1.3600000143051147px);
        -webkit-backdrop-filter: blur(1.3600000143051147px);
        box-shadow: none;
        text-transform: none;
      }
    }
  }

  .welR {
    img {
      // display: flex;
      // position: relative;
      width: 200px;
      height: 200px;
      // position: absolute;
      // top: -3px;
      // right: -215px;
      margin-top: -79px;
      margin-right: -16px;
      // z-index: 1;
    }
  }
}

.modaluiOver {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media screen and (max-width: 800px) {
  .welCard {
    .welL {
      .welText {
        gap: 8px;
        .welCardTitle {
          font-size: 20px;

          img {
            width: 40px;
            height: 20px;
          }
        }

        .welCardText {
          font-size: 12px;
        }
      }

      .welBtn {
        Button {
          height: 30px;
        }
      }
    }

    .welR {
      img {
        width: 100px;
        height: 100px;

        margin-top: -64px;
        margin-right: -16px;
      }
    }
  }
}
