@import "../../variable/colors.scss";

.recentTrans {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 722px;
  width: auto;
  // width: 40vw;
  height: auto;
  padding: 24px 16px;
  border-radius: 12px;
  border: 1px solid $gray-300;
  background: $primary-white;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  margin: 10px;

  .recentTransIn {
    display: flex;
    width: 100%;
    height: auto;
    padding: 16px 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid $gray-200;
    background: $primary-white;

    /* Shadow cards */
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .recentTransHead {
      display: flex;
      width: 95%;
      flex-direction: column;
      /* margin: 16px; */
      margin: 0px 16px;
      justify-content: space-between;
      gap: 16px;

      .recentTransHeadIn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .recentTransHeadD {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h3 {
            color: #1e293b;

            /* Large bold */
            //   font-family: Noto Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 25.2px */
          }

          p {
            color: #64748b;

            /* Small */
            // font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
        }

        button {
          text-transform: none;
        }
      }

      .recentTransFilter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        Button {
          @include outlineBtn;
          text-transform: none;
        }

        .recentTranSearch {
          height: 40px;
          width: 240px;
        }
      }
    }

    .recentTranTable {
      display: flex;
      width: 100%;

      .noData {
        width: 58vw;
        display: flex;
        justify-content: space-around;
        p {
          border: 2px dashed #b7b7b7;
          padding: 25px;
          border-radius: 10px;
          display: flex;
          margin-top: 10%;
          color: #878787;
          margin: 4.55em 3rem;
        }
      }

      table {
        width: 100%;
        // border: 1px solid $gray-400;

        border-collapse: collapse;

        thead {
          width: 689px;
          height: 40px;
          border: 1px solid $gray-300;
          background: $gray-100;
          width: 689px;
          height: 40px;
          border-bottom: 1px solid black; /* add bottom border */
          border-left: none; /* remove left border */
          border-right: none; /* remove right border */
          background: $gray-100;

          tr th {
            padding: 8px 0px;
            color: rgba(100, 116, 139, 0.7);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
            padding: 0px 20px;
          }
        }
      }

      tbody {
        tr {
          width: 689px;
          padding: 0px 16px;
          // border: 1px solid black; /* add border */
          // border-top: none; /* remove top border */
          border: none;
          border-top: 1px solid $gray-400;

          td {
            width: 689px;
            padding: 9px 20px;

            text-align: left;
            color: $secondary;
            /* Small */
            //   font-family: Noto Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            .tableTrans {
              color: $dark;
              //   font-family: Noto Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 162.5%;
              letter-spacing: -0.8px;
              text-align: left;
            }
            .tableWallet {
              display: flex;
              .tableHolderImg {
                padding-right: 20px;

                img {
                  width: 32px;
                  height: 32px;
                  flex-shrink: 0;
                  border-radius: 50%;
                }
              }

              .tableHolderName {
                padding: 0%;
                color: $dark;
                /* h6 */
                // font-family: Noto Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 162.5%;
                letter-spacing: -0.8px;
              }
            }

            .operation {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .recentTrans {
    .recentTransIn {
      .recentTransHead {
        .recentTransHeadIn {
          margin-right: 10px;
          .recentTransHeadD {
            width: 50vw;
            h3 {
              font-size: 12px;
              font-weight: 500;
            }

            p {
              font-size: 10px;
              font-weight: 400;
            }
          }

          button {
            text-transform: none;
            width: 45px !important;
            height: 25px !important;
            font-size: 10px !important;
          }
        }

        .recentTransFilter {
          Button {
            font-size: 10px;
          }
        }
      }

      .recentTranTable {
        overflow-x: scroll;
        max-width: 80vw;

        table {
          thead {
            tr th {
              font-size: 12px;
              font-weight: 700;
              line-height: 16px;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 10px;
              font-weight: 400;

              .tableTrans {
                font-size: 10px;
                font-weight: 600;
                line-height: 162.5%;
                letter-spacing: -0.8px;
              }
              .tableWallet {
                .tableHolderName {
                  font-size: 10px;
                  font-weight: 600;
                  line-height: 162.5%;
                  letter-spacing: -0.8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
