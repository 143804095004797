.modalBody {
  .details {
    background: rgba(#d3dae6, 40%);
    margin: 1rem 0;
    border-radius: 6px;
    padding: 1rem;
    .fRow {
      display: flex;
      .item {
        padding: 5px 0;
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 25px;
        }
        &:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
    + p {
      margin-bottom: 1rem;
    }
  }
  .note {
    opacity: 0.6;
  }
}
